<template>
  <UForm
    ref="form"
    :schema="schema"
    :state="state"
    @submit="onSubmit"
    @error="onError"
  >
    <slot />
  </UForm>
</template>

<script lang="ts" setup generic="T extends unknown, S extends Record<string, unknown>">
import type { ZodSchema } from 'zod'
import { cloneDeep } from 'lodash-es'
import type { FormErrorEvent, FormSubmitEvent } from '#ui/types'

type FormValue = FormSubmitEvent<S>

defineProps<{
  schema: ZodSchema<T>
  state: S
}>()

const toast = useToast()
const form = ref()

const emit = defineEmits<{
  (e: 'submit', data: FormValue['data'], form: Record<string, CallableFunction>): void
}>()

const onError = (event: FormErrorEvent) => {
  toast.add({
    title: event.errors?.[0]?.message ?? 'Error',
    color: 'red',
  })
}

const onSubmit = (event: FormValue) => {
  emit('submit', cloneDeep(event.data), form.value)
}
</script>
